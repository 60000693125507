<template>
  <v-dialog v-model="internalValue" content-class="product-detail-video-dialog">
    <div class="product-detail-video-dialog__close">
      <base-icon
        color="white"
        mdi="close"
        large
        @click="internalValue = false"
      ></base-icon>
    </div>
    <video
      v-if="internalValue && url"
      controls
      autoplay
      controlslist="nodownload"
    >
      <source :src="url" type="video/mp4" />
    </video>
  </v-dialog>
</template>

<script>
import BaseIcon from '~/components/base/icon/BaseIcon'
export default {
  name: 'ProductDetailVideoDialog',
  components: { BaseIcon },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.product-detail-video-dialog {
  box-shadow: none;
  margin: 0;
  display: flex;
  justify-content: center;
  max-width: 95%;
  position: relative;
  padding-top: 44px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    max-width: 70%;
  }

  &__close {
    position: absolute;
    top: 0;
    left: 0;
    text-align: right;
    padding-right: 7px;
  }

  video {
    height: auto;
    width: auto;
    //max-height: calc(100vh - 150px);
    max-width: 100%;
  }
}
</style>
